import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/krafton-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/redacted-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/sds-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/tear-effect.svg");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalHeader"] */ "/app/src/components/global-header/GlobalHeader.component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/text-block/TextBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/global-footer/GlobalFooter.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/src/components/video/Video.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/features/authentication/context/Session.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteBanners"] */ "/app/src/features/site-banners/components/site-banners/SiteBanners.component.tsx");
